import logo from './img/az_logo.webp';
import icon_amazon from './icon/icon_amazon.png';
import icon_bing from './icon/icon_bing.png';
import icon_calil from './icon/icon_calil.png';
import icon_duckduckgo from './icon/icon_duckduckgo.png';
import icon_google from './icon/icon_google.png';
import icon_mercari from './icon/icon_mercari.png';
import icon_twitter from './icon/icon_twitter.png';
import icon_wiki from './icon/icon_wiki.png';
import icon_yahoo from './icon/icon_yahoo.png';
import icon_yahoo_shooping from './icon/icon_yahoo_shopping.png';
import icon_yahoo_freem from './icon/icon_yahoo_freem.png';
import icon_yahoo_oku from './icon/icon_yahoo_oku.png';
import icon_yodobashi from './icon/icon_yodobashi.png';
import icon_youtube from './icon/icon_youtube.png';
import icon_sakurachecker from './icon/icon_sakurachecker.png';
import icon_aliexpress from './icon/icon_aliexpress.png';
import './App.css';
import { useState } from "react";
import { useForm } from "react-hook-form";

/*

import reactStringReplace from 'react-string-replace';


  google: "http://www.google.com/search?q=TESTSEARCH",
  bing: "http://www.bing.com/search?q=TESTSEARCH",
  yahoo: "https://search.yahoo.co.jp/search?p=TESTSEARCH",
  duckduckgo: "https://duckduckgo.com/?q=TESTSEARCH",
  amazon: "https://www.amazon.co.jp/s?k=TESTSEARCH",
  mercari: "https://jp.mercari.com/search?keyword=TESTSEARCH",
  yodobashi: "https://www.yodobashi.com/?word=TESTSEARCH",
  yahooshopping: "https://shopping.yahoo.co.jp/search?p=TESTSEARCH",
  paypaymall: "https://paypaymall.yahoo.co.jp/search?p=TESTSEARCH",
  paypayfreem: "https://paypayfleamarket.yahoo.co.jp/search/TESTSEARCH",
  yahuoku: "https://auctions.yahoo.co.jp/search/search?p=TESTSEARCH",
  calil: "https://calil.jp/search?q=TESTSEARCH",
  twitter: "http://twitter.com/#search?q=TESTSEARCH",
  wiki: "http://ja.wikipedia.org/wiki/Special:Search?search=TESTSEARCH",
  youtube: "http://www.youtube.com/results?search_query=TESTSEARCH"
  sakurachecker: "https://sakura-checker.jp/itemsearch/?word=TESTSEARCH"
  aliexpress: "https://sakura-checker.jp/itemsearch/?word=TESTSEARCH"


  https://ja.aliexpress.com/w/wholesale-https://ja.aliexpress.com/w/wholesale-TESTSEARCH.html
  */

const jump_list = {
  google: "http://www.google.com/search?q=",
  bing: "http://www.bing.com/search?q=",
  yahoo: "https://search.yahoo.co.jp/search?p=",
  duckduckgo: "https://duckduckgo.com/?q=",
  amazon: "https://www.amazon.co.jp/s?k=",
  mercari: "https://jp.mercari.com/search?keyword=",
  yodobashi: "https://www.yodobashi.com/?word=",
  yahooshopping: "https://shopping.yahoo.co.jp/search?p=",
  paypayfreem: "https://paypayfleamarket.yahoo.co.jp/search/",
  yahuoku: "https://auctions.yahoo.co.jp/search/search?p=",
  calil: "https://calil.jp/search?q=",
  twitter: "http://twitter.com/search?q=",
  wiki: "http://ja.wikipedia.org/wiki/Special:Search?search=",
  youtube: "http://www.youtube.com/results?search_query=",
  sakurachecker: "https://sakura-checker.jp/itemsearch/?word=",
  aliexpress: "https://ja.aliexpress.com/w/wholesale-",

};


export function App() {
  const { register, setValue, handleSubmit } = useForm();
  const [formdata, setData] = useState({});
  const url_end = formdata.s === "aliexpress" ? ".html" : "";
  const location_url = jump_list[formdata.s] + formdata.q + url_end;

  /*
  const url_end = formdata.a === "aliexpress" ? ".html" : null;
  const location_url = location_url + url_end;

  const location_url = jump_list[formdata.s] + formdata.q + url_end;
  $testa = $testa.$testb;

  */


  if (formdata.s) {
    window.open(location_url, '_blank')
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />

        <form onSubmit={handleSubmit((formdata) => setData(formdata))}>
          <div className="txt-shadow" style={{ 'font-size': '1.3rem' }}>
            search@aznable.biz
          </div>

          <div style={{ 'margin': '1rem 0rem 1rem 0rem' }}>
            <input {...register("q")} type="text" name="q" placeholder='キーワードを入力' className='form-input' autofocus />
          </div>
          <div style={{ 'margin': '0rem 1rem 0rem 1rem', 'font-size': '1rem', 'text-shadow': '0 0 15px black', 'text-align': 'center' }}>
            web search
          </div>
          <div style={{ 'width': '100%', 'text-align': 'center' }}>
            <input onClick={() => { setValue("s", "google"); }} type="image" src={icon_google} className="search-icon" name="s" value="google" alt="google" />
            <input onClick={() => { setValue("s", "bing"); }} type="image" src={icon_bing} className="search-icon" name="s" value="bing" alt="bing" />
            <input onClick={() => { setValue("s", "yahoo"); }} type="image" src={icon_yahoo} className="search-icon" name="s" value="yahoo" alt="yahoo" />
            <input onClick={() => { setValue("s", "duckduckgo"); }} type="image" src={icon_duckduckgo} className="search-icon" name="s" value="duckduckgo" alt="duckduckgo" />
          </div>
          <div style={{ 'margin': '0rem 1rem 0rem 1rem', 'font-size': '1rem', 'text-shadow': '0 0 15px black', 'text-align': 'center' }}>
            shopping
          </div>
          <div style={{ 'width': '100%', 'text-align': 'center' }}>
            <input onClick={() => { setValue("s", "amazon"); }} type="image" src={icon_amazon} className="search-icon" name="s" value="amazon" alt="amazon" />
            <input onClick={() => { setValue("s", "sakurachecker"); }} type="image" src={icon_sakurachecker} className="search-icon" name="s" value="sakurachecker" alt="sakurachecker" />
            <input onClick={() => { setValue("s", "mercari"); }} type="image" src={icon_mercari} className="search-icon" name="s" value="mercari" alt="mercari" />
            <input onClick={() => { setValue("s", "yodobashi"); }} type="image" src={icon_yodobashi} className="search-icon" name="s" value="yodobashi" alt="yodobashi" />
            <input onClick={() => { setValue("s", "aliexpress"); }} type="image" src={icon_aliexpress} className="search-icon" name="s" value="aliexpress" alt="aliexpress" />
          </div>
          <div style={{ 'width': '100%', 'text-align': 'center' }}>
            <input onClick={() => { setValue("s", "yahooshopping"); }} type="image" src={icon_yahoo_shooping} className="search-icon" name="s" value="yahooshopping" alt="yahooshopping" />
            <input onClick={() => { setValue("s", "paypayfreem"); }} type="image" src={icon_yahoo_freem} className="search-icon" name="s" value="paypayfreem-freem" alt="paypayfreem" />
            <input onClick={() => { setValue("s", "yahuoku"); }} type="image" src={icon_yahoo_oku} className="search-icon" name="s" value="yahuoku" alt="yahuoku" />
          </div>
          <div style={{ 'margin': '0rem 1rem 0rem 1rem', 'font-size': '1rem', 'text-shadow': '0 0 15px black', 'text-align': 'center' }}>
            etc
          </div>
          <div style={{ 'width': '100%', 'text-align': 'center' }}>
            <input onClick={() => { setValue("s", "calil"); }} type="image" src={icon_calil} className="search-icon" name="s" value="calil" alt="calil" />
            <input onClick={() => { setValue("s", "twitter"); }} type="image" src={icon_twitter} className="search-icon" name="s" value="twitter" alt="twitter" />
            <input onClick={() => { setValue("s", "wiki"); }} type="image" src={icon_wiki} className="search-icon" name="s" value="wiki" alt="wiki" />
            <input onClick={() => { setValue("s", "youtube"); }} type="image" src={icon_youtube} className="search-icon" name="s" value="youtube" alt="youtube" />
          </div>
          <div style={{ 'margin-top': '1rem', 'font-size': '0.8rem', 'text-shadow': '0 0 15px black' }}>
            キーワードを入力後、検索対象のアイコンを選択
          </div>
        </form>

        <div style={{ 'margin-top': '1rem' }}>
          <a
            className="App-link"
            href="https://blog.aznable.biz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            blog@aznable.biz
          </a>
        </div>
        <div style={{ 'margin-top': '1rem' }}>
          <a
            className="App-link"
            href="https://news366.aznable.biz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            news366@aznable.biz
          </a>
        </div>
        <div style={{ 'margin': '2rem 0rem 2rem 0rem', 'font-size': '1rem' }}>
          <span style={{ 'color': 'black', 'font-size': '1rem', 'position': 'fixed', 'right': '10px', 'bottom': '10px' }}>Ver. Mar-29th-2023</span>
        </div>
      </header>
    </div >

  );



};




export default App;
